@tailwind base;
@tailwind components;
@tailwind utilities;

/*
* {
  border: 1px limegreen solid;
}
*/

::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
::-webkit-scrollbar-thumb {
  background: #888; 
}
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media only screen and (max-width: 500px) {
  header {
    height: 58px!important;
  }
  #footer {
    height: 58px!important;
  }

  header p {
    font-size: 16px!important;
  }
  header div div > a {
    font-size: 16px!important;
  }
  #footer .text-textprimary {
    font-size: 16px!important;
  }

  header div a img {
    width: 38px!important;
    height: 38px!important;
  }
  #footer img {
    width: 38px!important;
    height: 38px!important;
  }

  header div div div img {
    width: 20px!important;
    height: 20px!important;
    margin-right: 6px!important;
  }

  header button {
    width: 33px!important;
    height: 33px!important;
  }
  header button svg {
    width: 17px!important;
    height: 17px!important;
  }
  #footer button {
    width: 33px!important;
    height: 33px!important;
  }
  #footer button svg {
    width: 17px!important;
    height: 17px!important;
  }

  #footer div div .flex-row {
    margin-right: 0.5rem!important;
  }
}
@media only screen and (max-width: 450px) {
  header {
    height: 52px!important;
  }
  #footer {
    height: 52px!important;
  }

  header p {
    font-size: 15px!important;
  }
  header div div > a {
    font-size: 15px!important;
  }
  #footer .text-textprimary {
    font-size: 15px!important;
  }

  header div a img {
    width: 32px!important;
    height: 32px!important;
  }
  #footer img {
    width: 32px!important;
    height: 32px!important;
  }

  header div div div img {
    width: 18px!important;
    height: 18px!important;
    margin-right: 4px!important;
  }

  header button {
    width: 28px!important;
    height: 28px!important;
  }
  header button svg {
    width: 13px!important;
    height: 13px!important;
  }
  #footer button {
    width: 28px!important;
    height: 28px!important;
  }
  #footer button svg {
    width: 13px!important;
    height: 13px!important;
  }

  #footer div div .flex-row {
    margin-right: 0.25rem!important;
  }
}

#blinkingicon {
  background:rgba(255, 66, 66, 0.9);
  border: 1px solid rgba(0,0,0,0.25);
  transform: scale(1);
	animation: colorchange 600s, pulse 2s infinite;
}
@keyframes pulse {
	0% {
		transform: scale(0.85);
    box-shadow: 0 0 0 0 rgba(140, 140, 140, 0.85);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.85);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}
@keyframes colorchange {
  0.00% {
      background: rgba(29, 167, 29, 0.9); 
      box-shadow: 0 0 0.5em 0.15em rgba(29, 167, 29, 0.9); 
  }
  20.00% {
      background: rgba(29, 167, 29, 0.9); 
      box-shadow: 0 0 0.5em 0.15em rgba(29, 167, 29, 0.9); 
  }
  20.01% {
      background: rgba(255, 255, 20, 0.9);
      box-shadow: 0 0 0.5em 0.15em rgba(255, 255, 20, 0.9);
  }
  50.00% {
      background:rgba(255, 255, 20, 0.9);
      box-shadow: 0 0 0.5em 0.15em rgba(255, 255, 20, 0.9);
  }
  50.01% {
      background:rgba(255, 177, 31, 0.9);
      box-shadow: 0 0 0.5em 0.15em rgba(255, 177, 31, 0.9);
  }
  99.99% {
      background:rgba(255, 177, 31, 0.9);
      box-shadow: 0 0 0.5em 0.15em rgba(255, 177, 31, 0.9);
  }
  100.00% {
      background:rgba(255, 66, 66, 0.9);
      box-shadow: 0 0 0.5em 0.15em rgba(255, 66, 66, 0.9);
  }
}

#gradienttimer { 
    background: linear-gradient(90deg, rgb(90, 229, 103), rgb(68, 190, 212), rgb(166, 101, 250));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: max-content;
}
/*
        accent1: 'rgb(90, 229, 103)',
        accent2: 'rgb(68, 190, 212)',
        accent3: 'rgb(166, 101, 250)',
*/

.hideMenuNav {
  display: none;
}
.showMenuNav {
  display: block;
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}